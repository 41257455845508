import React from 'react'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import { navigate } from 'vike/client/router'
import { PLATFORM_API_URL, PLATFORM_API_VERSION } from '../../config'

const ArticleCard = ({ articleID, title, thumbnail, date, description }) => {
  const t = useTranslation()
  const image = `${PLATFORM_API_URL}/${PLATFORM_API_VERSION}/blog-resource?file=${thumbnail}`

  return (
    <a
      href={`/blog?article=${articleID}`}
      className="relative w-full sm:max-w-[580px] md:w-full mx-auto rounded-lg p-6 bg-paper overflow-hidden hover:shadow-md cursor-pointer hover:scale-105 transition delay-150 duration-300"
    >
      {/* 200px img height is ok for larger screens; below lg, img height is set to 'auto'. */}
      <div className="w-full h-auto lg:h-[200px]">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>

      <Typography variant="h3" className="mt-6 mb-2">
        {title}
      </Typography>

      <Typography variant="h4" className="mb-4">
        {date}
      </Typography>

      <Typography variant="paragraph" className="mb-4">
        {description}
      </Typography>
    </a>
  )
}

export default ArticleCard
